import './App.css';
import Tournaments from './Tournaments';
import Missions from './Missions';
import StoreItemsQuick from './StoreItemsQuick';
import { useState } from 'react';

function App() {

  const [activeTab, setActiveTab] = useState('Tournaments');

  return (
    <div className="App">
      <div className="tabs">
      <button className={`tab ${activeTab === 'Tournaments' ? 'active' : ''}`} onClick={() => setActiveTab('Tournaments')}>Torneios</button>
      <button className={`tab ${activeTab === 'Missions' ? 'active' : ''}`} onClick={() => setActiveTab('Missions')}>Missões</button>
      <button className={`tab ${activeTab === 'StoreItemsQuick' ? 'active' : ''}`} onClick={() => setActiveTab('StoreItemsQuick')}>Loja</button>
      </div>

      <div className="tab-content">
        {activeTab === 'Tournaments' && <Tournaments />}
        {activeTab === 'Missions' && <Missions />}
        {activeTab === 'StoreItemsQuick' && <StoreItemsQuick />}
      </div>
    </div>
  );
}

export default App;
