import React, { createRef, useEffect, useRef, useState } from 'react';
import './Missions.scss';

const missionStatus = (mission) => {
    if (mission.is_locked) {
        return 'Bloqueado';
    } else if (mission.is_completed) {
        return 'Completo';
    } else if (mission.is_opted_in) {
        return 'Em progresso';
    } else if (mission.is_requires_optin) {
        return 'Participar';
    } else {
        return '';
    }
};

const getStatusClassName = (mission) => {
    switch (missionStatus(mission)) {
        case 'Bloqueado':
            return 'locked';
        case 'Completo':
            return 'completed';
        case 'Em progresso':
            return 'in-progress';
        case 'Participar':
            return 'requires-optin';
        default:
            return '';
    }
};

const Missions = () => {
    const [loading, setLoading] = useState(true);
    const [missions, setMissions] = useState(null);
    const [activeMission, setActiveMission] = useState(null);
    const [timeLeftForMissions, setTimeLeftForMissions] = useState({});

    const missionsContainerRef = useRef(null);
    const missionRefs = useRef([]);
    missionRefs.current = missions ? missions.map((_, i) => missionRefs.current[i] ?? createRef()) : [];

    const smarticoApi = window._smartico.api;
    const smarticoDP = window._smartico.dp;

    const calculateTimeLeft = (mission) => {
        const currentTime = new Date().getTime();
        const endTime = mission.dt_start + mission.time_limit_ms;
        let timeLeft = endTime - currentTime;

        if (timeLeft < 0) {
            return null;
        }

        let days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
        let hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        let minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

        return { days, hours, minutes, seconds };
    };

    const handleExpand = (missionId) => {
        setActiveMission(activeMission === missionId ? null : missionId);
        setTimeout(() => {
            const missionIndex = missions.findIndex((mission) => mission.id === missionId);
            if (missionIndex !== -1 && missionsContainerRef.current) {
                const missionElement = missionRefs.current[missionIndex].current;
                const container = missionsContainerRef.current;

                if (missionElement) {
                    const missionTop = missionElement.getBoundingClientRect().top;
                    const containerTop = container.getBoundingClientRect().top;
                    const scrollPosition = missionTop - containerTop + container.scrollTop - 12;

                    container.scrollTo({
                        top: scrollPosition,
                        behavior: 'smooth',
                    });
                }
            }
        }, 0);
    };

    useEffect(() => {
        const loadData = async () => {
            const missionsResponse = await smarticoApi.getMissions({ onUpdate: missionsUpdates });

            setMissions(missionsResponse);
            setLoading(false);
        };

        loadData();
    }, [smarticoApi]);

    useEffect(() => {
        const interval = setInterval(() => {
            const newTimeLeft = {};
            missions?.forEach((mission) => {
                newTimeLeft[mission.id] = calculateTimeLeft(mission);
            });
            setTimeLeftForMissions(newTimeLeft);
        }, 1000);

        return () => clearInterval(interval);
    }, [missions]);

    const missionsUpdates = (missions) => {
        if (missions && missions.length > 0) {
            setMissions(missions);
        }
    };

    if (loading) {
        return null;
    }

    return (
        <div className="missions-container">
            <div className="missions-header">
                <div className="title">{`MIssões (${missions.length})`}</div>
                <div className="view-all-btn" onClick={() => smarticoDP('dp:gf_missions&standalone=true')}>
                    Ver todas
                </div>
            </div>
            <div className="missions-list" ref={missionsContainerRef}>
                {missions.map((mission, index) => {
                    const timeLeft = timeLeftForMissions[mission.id];
                    return (
                        <div
                            ref={missionRefs.current[index]}
                            className={`mission ${activeMission === mission.id ? 'active' : ''}`}
                            onClick={() => handleExpand(mission.id)}
                            key={mission.id}
                        >
                            <div className="mission-block">
                                <div className="mission-left">
                                    <div className="ribbon"></div>
                                    {mission.hint_text && (
                                        <div className="wrapper">
                                            <input type="checkbox" />
                                            <div className="btn">i</div>
                                            <div className="tooltip">
                                                <div className="tooltip-text">{mission.hint_text}</div>
                                            </div>
                                        </div>
                                    )}
                                    <div className="image" style={{ backgroundImage: `url('${mission.image}')` }}></div>
                                </div>
                                <div className="mission-right">
                                    <div className="mission-info">
                                        <div className="name" dangerouslySetInnerHTML={{ __html: mission.name }}></div>
                                        <div className={`status ${getStatusClassName(mission)}`}>{missionStatus(mission)}</div>
                                    </div>
                                    <div className="description" dangerouslySetInnerHTML={{ __html: mission.description }}></div>
                                    {mission.reward && (
                                        <div className="reward">
                                            <span>Recompensa:</span> {mission.reward}
                                        </div>
                                    )}
                                </div>
                            </div>
                            {mission.progress > 0 && (
                                <div className="progress-bar-block">
                                    <div className="progress-bar-text">
                                        <div className="label">Progresso</div>
                                        <div className="numbers">
                                            {mission.progress} <span>/ 100%</span>
                                        </div>
                                    </div>
                                    <div className="progress-bar">
                                        <div className="progress" style={{ width: `${mission.progress}%` }}></div>
                                    </div>
                                </div>
                            )}
                            <div className="tasks">
                                {mission.tasks.map((task) => {
                                    return (
                                        <div className={`task ${task.is_completed ? 'completed' : ''}`} key={task.id}>
                                            <div className="task-icon">✔</div>
                                            <div className="task-name" dangerouslySetInnerHTML={{ __html: task.name }}></div>
                                            {task.points_reward > 0 && (
                                                <div className="task-reward">{task.points_reward} pts</div>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>

                            {mission.related_games.length > 0 && (
                                <div onClick={(event) => event.stopPropagation()} className="related-games">
                                    <div className="title">Relacionados</div>
                                    <ScrollItems className="games-list-carousel">
                                        {mission.related_games.map((g, i) => (
                                            <div
                                                key={g.ext_game_id + '_' + i}
                                                className="image"
                                                onClick={() => alert(`Open the game: ${g.game_public_meta.name}`)}
                                                style={{ backgroundImage: `url('${g.game_public_meta.image}')` }}
                                            >
                                                <div className="overlay"></div>
                                            </div>
                                        ))}
                                    </ScrollItems>
                                </div>
                            )}

                            {!mission.is_opted_in && mission.is_requires_optin && !mission.is_locked && (
                                <div
                                    className="optin-btn"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        smarticoApi.requestMissionOptIn(mission.id);
                                    }}
                                >
                                    Participar
                                </div>
                            )}
                            {mission.is_locked && (
                                <div className="unlock-description">
                                    <div className="title">Como desbloqueio esta missão?</div>
                                    <div className="description">{mission.unlock_mission_description}</div>
                                </div>
                            )}
                            {timeLeft && (
                                <div className="time">
                                    <div className="time-text">Duração da missão</div>
                                    <div className="timer">
                                        {timeLeft.days > 0 && (
                                            <div className="time-number">
                                                {timeLeft.days < 10 ? '0' : ''}
                                                {timeLeft.days}
                                                <span>d</span>
                                            </div>
                                        )}
                                        <div className="time-number">
                                            {timeLeft.hours < 10 ? '0' : ''}
                                            {timeLeft.hours}
                                            <span>h</span>
                                        </div>
                                        <div className="time-number">
                                            {timeLeft.minutes < 10 ? '0' : ''}
                                            {timeLeft.minutes}
                                            <span>m</span>
                                        </div>
                                        {timeLeft.days <= 0 && (
                                            <div className="time-number">
                                                {timeLeft.seconds < 10 ? '0' : ''}
                                                {timeLeft.seconds}
                                                <span>s</span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

const ScrollItems = ({ className, children }) => {
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const carouselRef = useRef(null);

    const onDragStart = (e) => {
        setIsDragging(true);
        setStartX(e.pageX - carouselRef.current.offsetLeft);
        setScrollLeft(carouselRef.current.scrollLeft);
    };

    const onDragEnd = () => {
        setIsDragging(false);
    };

    const onDragMove = (e) => {
        if (!isDragging) {
            return;
        }

        e.preventDefault();
        const x = e.pageX - carouselRef.current.offsetLeft;
        const walk = (x - startX) * 1.5;
        carouselRef.current.scrollLeft = scrollLeft - walk;
    };

    return (
        <div
            className={className}
            ref={carouselRef}
            onMouseDown={onDragStart}
            onMouseLeave={onDragEnd}
            onMouseUp={onDragEnd}
            onMouseMove={onDragMove}
            onTouchStart={(e) => onDragStart(e.touches[0])}
            onTouchEnd={onDragEnd}
            onTouchMove={(e) => onDragMove(e.touches[0])}
        >
            {children}
        </div>
    );
};

export default Missions;
