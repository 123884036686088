import React, { useState, useRef } from 'react';
import './Template.scss';

const chooseRibbon = (item) => {
    switch (item) {
        case 'sale':
            return 'https://static3.smr.vc/07a23892196ef8e2db49ae-label-sale.png';
        case 'hot':
            return 'https://static3.smr.vc/25a434558191ce69a03382-label-hot.png';
        case 'new':
            return 'https://static3.smr.vc/b0be11af434336bcffdb6f-label-new.png';
        case 'vip':
            return 'https://static3.smr.vc/8a4e6fcc849de06302d0db-label-vip.png';
        default:
            return item;
    }
};

export const StoreItemTemplate = ({ storeItem, setPurchaseStatus }) => {
    const [flippedItem, setFlippedItem] = useState(false);
    const successButRef = useRef(null);
    const storeItemRef = useRef(null);

    const displayMessage = (message) => {
        setPurchaseStatus(message);
        setTimeout(() => setPurchaseStatus(''), 5000);
    };

    const handleBuyStoreItem = (storeItemID) => {
        window._smartico.api.buyStoreItem(storeItemID).then((response) => {
            if (response.err_code === 0) {
                setFlippedItem(false);
                displayMessage('Successful purchase!');
                if (successButRef.current) {
                    successButRef.current.classList.add('success');
                    successButRef.current.innerHTML = '✔';
                    setTimeout(() => {
                        successButRef.current.innerHTML = 'Quick Buy';
                        successButRef.current.classList.remove('success');
                    }, 1500);
                }
            } else if (response.err_code === 11000) {
                displayMessage('Saldo insuficiente');
                applyShakeAnimation();
            } else {
                displayMessage('Falha na compra!');
                applyShakeAnimation();
            }
        });
    };

    const applyShakeAnimation = () => {
        if (storeItemRef.current) {
            storeItemRef.current.classList.add('shake');
            setTimeout(() => {
                storeItemRef.current.classList.remove('shake');
            }, 500); // Duration of the shake animation
        }
    };

    return (
        <div ref={storeItemRef} key={storeItem.id} className={`store-item ${flippedItem ? 'flipped' : ''}`}>
            <div className="flip-container">
                <div className="store-item-front">
                    <div className="ribbon" style={{ backgroundImage: `url("${chooseRibbon(storeItem.ribbon)}")` }}></div>
                    {storeItem.hint_text && (
                        <div className="wrapper">
                            <input type="checkbox" />
                            <div className="btn">i</div>
                            <div className="tooltip">
                                <div className="tooltip-text">{storeItem.hint_text}</div>
                            </div>
                        </div>
                    )}
                    <div className="image" style={{ backgroundImage: `url('${storeItem.image}')` }}></div>
                    <div className="title" dangerouslySetInnerHTML={{ __html: storeItem.name }}></div>
                    <div className="price">
                        Preço: <span>{storeItem.price} pt</span>
                    </div>
                    <div className="quick-buy-btn" ref={successButRef} onClick={() => setFlippedItem(true)}>
                    Compra rápida
                    </div>
                </div>
                <div className="store-item-back">
                    <div className="description">Isso deduzirá moedas da sua conta. Você quer prosseguir?</div>
                    <div className="price">
                        Preço: <span>{storeItem.price} pt</span>
                    </div>
                    <div className="cancel-btn" onClick={() => setFlippedItem(false)}>
                        Cancelar
                    </div>
                    <div
                        className={`buy-now-btn ${storeItem.can_buy ? '' : 'disabled'}`}
                        onClick={() => storeItem.can_buy && handleBuyStoreItem(storeItem.id)}
                    >
                        Comprar
                    </div>
                </div>
            </div>
        </div>
    );
};
