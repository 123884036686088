import { useEffect, useRef, useState } from 'react';
import './StoreItemsQuick.scss';
import { StoreItemTemplate } from './Template';

const StoreItemsQuick = () => {
    const [loading, setLoading] = useState(true);
    const [storeItemsList, setStoreItemsList] = useState(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const [purchaseStatus, setPurchaseStatus] = useState('');
    const carouselRef = useRef(null);

    const smarticoApi = window._smartico.api;
    const smarticoDP = window._smartico.dp;

    const enableSmoothScrolling = () => {
        if (carouselRef.current) {
            carouselRef.current.classList.add('smooth-scrolling');
        }
    };
    
    const disableSmoothScrolling = () => {
        if (carouselRef.current) {
            carouselRef.current.classList.remove('smooth-scrolling');
        }
    };

    const onDragStart = (e) => {
        disableSmoothScrolling();
        setIsDragging(true);
        setStartX(e.pageX - carouselRef.current.offsetLeft);
        setScrollLeft(carouselRef.current.scrollLeft);
    };

    const onDragEnd = () => {
        setIsDragging(false);
    };

    const onDragMove = (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - carouselRef.current.offsetLeft;
        const walk = (x - startX) * 1.5;
        carouselRef.current.scrollLeft = scrollLeft - walk;
    };

    const handleLeftArrowClick = () => {
        enableSmoothScrolling();
        if(carouselRef.current) {
            // Subtract the scroll amount
            carouselRef.current.scrollLeft -= 240;
        }
    };
    
    const handleRightArrowClick = () => {
        enableSmoothScrolling();
        if(carouselRef.current) {
            // Add the scroll amount
            carouselRef.current.scrollLeft += 240;
        }
    };

    useEffect(() => {
        const loadData = async () => {
            const storeItems = await smarticoApi.getStoreItems();

            setStoreItemsList(storeItems);
            setLoading(false);
        };

        loadData();
    }, [smarticoApi]);

    const getMessageClass = () => {
        switch (purchaseStatus) {
            case 'Compra bem-sucedida!':
                return 'success-message';
            case 'Fundos insuficientes!':
                return 'warning-message';
            case 'Falha na compra!':
                return 'error-message';
            default:
                return '';
        }
    };

    if (loading) {
        return null;
    }

    return (
        <div className="store-items-block">
            <div className="store-items-header">
                <div className="title">Loja rápida</div>
                <div className="view-all-btn" onClick={() => smarticoDP("dp:gf_store&standalone=true")}>Ver tudo</div>
            </div>
            <div 
                className="store-items-carousel"
                ref={carouselRef}
                onMouseDown={onDragStart}
                onMouseLeave={onDragEnd}
                onMouseUp={onDragEnd}
                onMouseMove={onDragMove}
                onTouchStart={(e) => onDragStart(e.touches[0])}
                onTouchEnd={onDragEnd}
                onTouchMove={(e) => onDragMove(e.touches[0])}
            >
                {storeItemsList.map((storeItem) => {
                    return (
                        <StoreItemTemplate key={storeItem.id} storeItem={storeItem} setPurchaseStatus={setPurchaseStatus} />
                    );
                })}
            </div>
            <div className="navigation-arrows">
                <div className={`purchase-status ${getMessageClass()}`}>{purchaseStatus}</div>
                <div className="arrows">
                    <div className="arrow left" onClick={handleLeftArrowClick}>{'<-'}</div>
                    <div className="arrow right" onClick={handleRightArrowClick}>{'->'}</div>
                </div>
            </div>
        </div>
    )
}

export default StoreItemsQuick;